// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-stufe-ohne-anschlag-js": () => import("/opt/build/repo/src/templates/stufe-ohne-anschlag.js" /* webpackChunkName: "component---src-templates-stufe-ohne-anschlag-js" */),
  "component---src-templates-stufe-mit-anschlag-js": () => import("/opt/build/repo/src/templates/stufe-mit-anschlag.js" /* webpackChunkName: "component---src-templates-stufe-mit-anschlag-js" */),
  "component---src-templates-page-js": () => import("/opt/build/repo/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-newspost-js": () => import("/opt/build/repo/src/templates/newspost.js" /* webpackChunkName: "component---src-templates-newspost-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-js": () => import("/opt/build/repo/src/pages/FAQ.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-fotos-js": () => import("/opt/build/repo/src/pages/fotos.js" /* webpackChunkName: "component---src-pages-fotos-js" */),
  "component---src-pages-gallerie-js": () => import("/opt/build/repo/src/pages/gallerie.js" /* webpackChunkName: "component---src-pages-gallerie-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("/opt/build/repo/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */)
}

